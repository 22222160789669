.quick-look {
    position: absolute;
    bottom: 5px;
    width: 50%;
    justify-content: space-between;
    gap: 10px;
}

.quick-look, .quick-look-button, .heart-button, 
.modal-overlay, .modal-content, #imageContainer {
    display: flex;
    align-items: center;
}

.quick-look-button,
.heart-button {
    margin: 0;
    padding: 0;
    color: white;
    justify-content: center;
    height: 30px;
}

.quick-look-button {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    width: 124px;
}

.quick-look-button:hover {
    background-color: white;
    color: black;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    cursor: pointer;
}

.heart-button {
    width: 30px;
}

.heart-button img {
    width: 100%;
    height: 100%;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darken the background */
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    text-align: left;
    padding: 28px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: black;
    display: flex;
    align-items: flex-start;
}

#imageContainer {
    width: 500px;
    height: 500px;
    justify-content: center;
    margin-right: 28px;
    padding: 0;
}

#QLImage {
    width: 100%;
    height: 100%;
}

#productDetails {
    /* background-color: aqua; */
    /* display: flex; */
    flex-direction: column;
    align-items: left;
    /* justify-content: center; */
    color: #000;
    font-weight: 600;
    /* margin-right: 90px; */
}

#productName {
    font-size: 25px; 
    width: 284px;
}

#productPrice {
    font-size: 16.62px;
}

#horizontalLine {
    height: 0.5px;
    width: 100%;
    background-color: black;
    margin-top: 20px;
    /* margin-bottom: 20px; */
}

#productDesc {
    /* background-color: red; */
    font-size: 16.62px;
    font-weight: 400;
    width: 284px;
}

#appBtn {
    width: 230px;
    height: 36.092px;
    color: white;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

#sellerDetailsQuickLook {
    display: flex;
    /* background-color: aquamarine; */
    position: relative; 
    width:  100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    zoom: 0.6;
    -moz-transform: scale(0.6);
}

#followLink {
    color: white;
    width: 300px;
    height: 50px;
}


