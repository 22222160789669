#parent-container {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;
}
#page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Make the main content grow to fill the available space */
#content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
}
#right-container, .cart-item {
    border-color: var(--border-color);
}

.cart-item {
    background-color: var(--cart-item-bg-color);
}

#cart-header {
    font-family: "Inter-Bold", Helvetica, serif;
    letter-spacing: 6.5px;
    font-size: 30px;
    font-weight: 600;
    margin-top: 60px;
    margin-left: 190px;
    margin-bottom: 60px;
}

#cart-summary {
    color: var(--text-color);
    font-family: "Inter-Regular", Helvetica, serif;
    letter-spacing: 8px;
    font-size: 24px;
    font-weight: 550;
    width: 100%;
    height: 100%;
    text-align: center;
    margin-bottom: 40px;
}

#left-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 80px;
    width: 900px;
    margin-right: 40px;
}

#right-container {
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 370px;
    height: 90%;
    padding: 30px;
}

.cart-item {
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    width: 100%;
    height: 250px;
    margin-bottom: 30px;
    padding-right: 20px;
    text-decoration: none;
    color: #000000;
}

.cart-item img {
    width: 450px;
    height: 250px;
    cursor: pointer;
}

.cart-summary-item {
    font-family: "Inter-Regular", Helvetica, serif;
    letter-spacing: 2px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
}

.accepted-payments img {
    width: 100%;
}

#remove-button {
    font-family: "Inter-Regular", Helvetica, serif;
    letter-spacing: 2px;
    font-size: 14px;
    color: #505050;
    cursor: pointer;
    margin-top: 23px;
}
