#navbar {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-between; /* Distribute space between elements */
    /*padding: 10px; !* Adjust padding as needed *!*/
    padding: 0;
    background-color: black;
    height: 70px;
    width: 100%;
}

.profile-or-login {
  display: flex;
  align-items: right;
  margin-right: 20px;
  font-size: 22px;
	color: white;
	font-weight: bold;
}

.profile-or-login span {
  font-weight: bold;
}

.profile-or-login a {
  text-decoration: none;
  color: white; 
}
.logo {
    margin-left: 30px;
    height: 40px;
    width: auto;
    margin-top: 15px;
    justify-content: center;
    cursor: pointer;
}

#mode {
    color: white;
}

.icon {
    height: 30px;
    cursor: pointer;
}

.icon-wrapper {
	
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    float: right;
    margin-right: 36px;
	

}

#searchBar {
    width: 290px;
    height: 42px;
    margin-left: -20px;
    border-radius: 5px;
    outline: none;
    border: none;
    padding-left: 10px;
    font-size: 13px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#searchBar:focus {
    outline: none;

}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    display: flex;
    align-items: center;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    /* background-color: rgb(217, 216, 215); */
    text-align: right;
    font-size: 9px;
    font-weight: 600;
    /* letter-spacing: 0.5px; */
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-image: url("../images/Light-3.png");
    background-position: center;
    background-size: 45px;
    box-shadow: 0px 2px 20px rgb(107, 107, 107);
    color: black;
    -webkit-transition: .4s;
    transition: .4s;
}

.mode {
    margin-top: 10px;
}

.light {
    margin-right: 6px;
}

.dark {
    margin-right: 31px;
}

input:checked + .slider {
    background-color: #2a2a2a;
    color: white;
}

input:checked + .slider:before {
    background-image: url("../images/Dark.png");
    background-position: center;
    background-size: 45px;
    background-color: transparent;
}

input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}