.box {
    height: 55px;
    position: relative;
    bottom: 20px;
    width: 100%;
    /* margin-top: 300px; */
}

.footer {
    display: flex;
    justify-content: space-between;
    /* color: black; */
}

.app-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 50px;
}

.app-button img {
    height: 40px;
    width: 130px;
}

.navbar {
    display: flex;
    gap: 60px;
    justify-content: center;
    width: 100%;
    padding-right: 130px;
    bottom: 15px;
}

.navbar a {
    font-family: "Inter-Regular", Helvetica, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
    outline: none;
    border: none;
    box-shadow: none;
    text-decoration: none;
    color: inherit;
}

