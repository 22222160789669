.parent {
  /* background-color: blueviolet; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* margin-right: auto; */
  margin-top: 20px;
  letter-spacing: 3px;
}

#wrapper {
  display: flex;
  justify-content: left;
  /* background-color: red; */
  flex-direction: row;
  align-items: flex-start;
}

.profile-pic {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* margin-left: 50px; */
}

.seller-details {
  /* background-color: rgb(255, 0, 0); */
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-top: 50px;
  margin-left: 50px;
  width: 400px;
}

.followers {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 90px;
  margin-top: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: 1.5px;
  width: 100%;
}

#follower-count {
  font-size: 24px;
  font-weight: lighter;
}

.bio {
  font-size: 24px;
  font-weight: regular;
  margin-top: 20px;
  margin-bottom: 20px;
  word-wrap: normal;
}

.name {
  font-size: 30px;
  font-weight: light;
  letter-spacing: 1px;
}

#circleImage {
  border-radius: 100%;
  overflow: hidden;
  /* border: 2px solid red; */
}

#profile {
  height: 250px;
  width: 250px;
}

#id {
  font-size: 14px;
  font-weight: lighter;
  margin-top: 20px;
}

#productsContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

#otherProducts {
  width: 500px;
  height: 500px;
}

.otherImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#live {
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 20px;
  justify-content: center;
  height: 30px;
  width: 140px;
  letter-spacing: 3px;
  font-size: 16px;
  cursor: pointer;
}

.appLink {
    cursor: pointer;
    width: 100%!important;
}

#followLink {
  background-color: black!important;
  color: white!important;
}

