
.dark-mode {
    background-color: black;
    color: white;
}

#mainContainer {
    margin: 100px;
    display: flex;
    /* align-items: flex-start; */
    align-items: center;
    flex-direction: column;
    /* width: 50vw; */
    /* height: 50vw; */
}

#detailsWrapper {
    display: flex;
    flex-direction: row;
}

#detailsImage {
    width: 500px;
    height: 500px;
}

#singleImageContainer {
    width: 125px;
    height: 125px;
    /* background-color: red;
    border: 1px solid purple; */
}

#large-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: 100%;
    cursor: pointer;
}

#selectedImage {
    /*opacity: 30%;*/
    width: 100%;
    max-width: 100%;
    height: 100%;
}

#detailsContainer {
    margin-left: 60px;
    flex: 1;
}

#detailName {
    font-size: 25px;
    /* font-size: 1.5vw; */
    font-weight: 600;
    margin-bottom: 20px;
}

#detailPrice {
    margin-top: 0;
    font-size: 22px;
    /* font-size: 1vw; */
    font-weight: 500;
}

#sizeContainer {
    display: flex;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    margin-top: 0;
    margin-bottom: 30px;
}


.dark-mode #sizeContainer {
    border: 1px solid white !important;
}

#detailSize {
    font-size: 15px;
    font-weight: 400;
}

#detailDesc {
    margin-top: 0;
    font-size: 20px;
    font-weight: 400;
}

.appLink {
    width: 300px;
    /* width: 20vw; */
    height: 50px;
    /* height: 3vw; */
    /* font-size: 1vw; */
    color: white;
    margin-bottom: 50px;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 2px;
}

.appLink .dark-mode {
    background-color: white!important;
    color: black!important;
} 

.dark-mode .appLink {
    background-color: white!important;
    color: black!important;
} 

#thumbnailContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    /*padding-top: 40px;*/
    margin-top: 40px;
    /* width: 200px;
    height: 200px; */
}

#singleImage {
    /* width: 160px;
    height: 160px; */
    width: 100%;
    height: 100%;
    object-fit: fill;
    cursor: pointer;
    margin-right: 22px;
    padding: 2px;
    max-width: 100%;
}
#appLink {
    background-color: black; 
    color: white; 
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#appLink:hover {
    background-color: #0056b3; 
}

.dark-mode #appLink {
    background-color: white; 
    color: black; 
}

#horizontal-line {
    height: 0.5px;
    width: 100%;
    background-color: black;
    margin-top: 250px;
}

 .dark-mode #horizontal-line {
    background-color: white !important;
 }


#arrow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

#arrow {
    font-size: 16px;
    cursor: pointer;
}

.background {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 1000;
    overflow: hidden;
}

.carousel-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
    overflow: hidden;
    object-fit: cover;
}

#carousel-image {
    height: 900px;
    width: 900px;
    object-fit: cover;
}