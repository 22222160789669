.menu-button {
  position: fixed;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.menu-container {
  position: fixed;
  top: -10px;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.darkmode-menu {
  position: fixed;
  top: -10px;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #121212;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.menu-header {
  text-align: right;
  padding: 10px;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: inherit;
  outline: none;
}
.close-button:hover,
.close-button:focus {
  color: inherit !important; 
  background: transparent !important;
  outline: none; 
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1100; 
}
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
}
.menu_item {
  padding: 15px 20px;
  cursor: pointer;
}
.darkModeMenu_item {
  padding: 15px 20px;
  cursor: pointer;
}
.darkModeMenu_item:hover {
  background-color: black;
}
.menu_item:hover {
  background-color: #f2f2f2;
}


