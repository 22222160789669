html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: none; /* Show scrollbar only when needed */
  
  transition: background-color 0.3s;
}
.page-container::-webkit-scrollbar {
    display: none;
}
body.light {
  background-color: #fff;
}

body.dark {
  color: white;
  background-color: #000;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: inherit;
  transition: border-color 0.3s;
}


h2 {
	
  margin-bottom: 20px;
  color: #000;
  transition: color 0.3s;
}
.login-button {
  display: block;
  margin: 0 auto;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.form-group {
  margin-bottom: 15px;
  text-align: center;
}


.form-group label {
  display: block;
  
  margin-bottom: 5px;
  color: #000;
  transition: color 0.3s;
  
}

.form-group input {
  width: calc(100% - 16px);
  padding: 8px;
  border: 1px solid #000;
  border-radius: 3px;
  transition: border-color 0.3s;
}

/* Dark mode styles */
.dark .login-container,
.dark h2,
.dark .form-group label {
  color: #fff;
  border-color: #fff;
}
.dark button {
  color: black;
  border-color: black;
}
.dark .login-button {
  background-color: black;
  border-color: #fff;
  color: white;
}

.page-container.dark .login-button {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

.login-button:hover {
  background-color: #ccc;
  border-color: #ccc;
}