/******************************** global.css ********************************/
:root {
    --background-color: white;
    --text-color: black;
    --border-color: #ccc;
    --cart-item-bg-color: #f9f9f9;
}

.dark-mode {
    --background-color: #121212;
    --text-color: white;
    --border-color: #333;
    --cart-item-bg-color: #1e1e1e;
}

* {
    font-family: "Inter-Regular", Helvetica, serif;
    font-style: normal;
    line-height: normal;
}

body {
    background-color: white;
    color: black;
    height: 100vh;
		
		transition: background-color 0.3s;
}

body.dark-mode {
    background-color: purple;
	  color: white;
    height: 100vh;
		transition: background-color 0.3s;
    
}


button,
button:hover {
    border-radius: 0;
    outline: none;
    border: none;
    box-shadow: none;
    background-color: black;
}

ul {
    list-style-type: none;
}



/* #darkModeButton {
    color: white;
} */

.page {
    display: flex;
    flex-direction: column;
	  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.category-name {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 6.5px;
    padding: 10px 10px 10px 10px;
		transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.category-name.empty {
    height: 84px;
}

.parent-container {
    display: flex;
    flex-direction: row;
    /*background-color: purple;*/
}

.sub-container {
    display: flex;
    flex-direction: row;
    margin-right: auto;
    justify-content: center;
		transition: background-color 0.3s, color 0.3s, border-color 0.3s;
    /* align-items: center; */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/***************** Sort *****************/

#listingOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

#sortContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    /*margin-left: auto;*/
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    margin-right: 10px;
    /*align-self: flex-end;*/
}

.sort-text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.sorted {
    font-weight: bold !important;
}

.separator {
    margin-right: 20px;
    margin-left: 20px;
}

.triangle {
    margin-left: 5px;
    font-size: 10px;
}
  
.sort-container {
    cursor: pointer;
}

#perPage {
    display: flex;
    align-items: center;
    margin-left: 40px;
    /* background-color: purple; */
}

#items {
    margin-left: 10px;
    width: 45px;
    font-size: 13px!important;
    height: 20px;
    border-radius: 10px;
    background: #F4F4F4;
    outline: none;
    box-shadow: none;
    border: none;
    text-align: center;
}

/***************** Product Listing *****************/
.products-grid {
    /* display: inline-flex;
    flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    align-items: start;
    justify-content: space-between;
    margin-right: 0;
    gap: 20px;
    margin-top: 0;
    width: 75vw;
    padding-right: 0;
}

#noProducts {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 1.2rem;
    color: #666;
    height: 100vh; /* Take up full viewport height */
}

.left-container {
    display: flex;
    margin-top: 100px;
    /*background-color: #61dafb;*/
}

.right-container {
    /* display: inline-block; */
    /* flex-grow: 1; */
    flex-direction: column;
}

.left-container, .right-container {
  min-height: 600px;
}

.pagination {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding-left: 30px;
    padding-bottom: 300px;
}

.pagination button {
    background-color: transparent;
    color: black;
    font-size: 18px;
}

.pagination button:hover {
    cursor: pointer;
}

.dark-mode .pagination button {
    color: white;
}
