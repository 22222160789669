.side-panel {
    height: auto;
    width: 13.26vw;
    margin-left: 20px;
}

.vertical-line {
    height: 1630px;
    width: 0.417px;
    background-color: black;
    margin-left: 30px;
}

.dark-mode {
    color: white;
}

.dark-mode .vertical-line {
    background-color: white;
}

/* .dark-mode .text-button:hover {
    color: white;
} */

.accordion-title {
    margin-bottom: 18px;
}

.accordion-title:hover {
    cursor: pointer;
}

.accordion-content, .side-panel .text .text-button, .accordion-title {
    font-weight: 400;
    letter-spacing: 2px;
    text-align: right;
}

.accordion-content {
    font-size: 12px;
    text-transform: uppercase;
    padding-bottom: 10px;
    cursor: pointer;
}

.side-panel .text .text-button, .accordion-title {
    background-color: transparent;
    width: 100%;
    margin-left: 0;
    font-size: 14px;
    cursor: pointer;
}

.side-panel .text .text-button:focus {
    outline: none;
}

.side-panel .text .text-button:hover, .accordion-title:hover {
    text-decoration: underline;
    /* color: black; */
}

.side-panel .space {
    display: block;
    padding-bottom: 40px;
}

#priceRange {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

#priceRange input {
    width: 75px;
    font-size: 14px;
    height: 35px;
    border-radius: 10px;
    background: #F4F4F4;
    outline: none;
    box-shadow: none;
    border: none;
    text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  
#range {
    margin: 0 12px;
    border-bottom: 1px solid black; /* Adjust the thickness and color as needed */
    width: 10px; /* Adjust the width as needed */
}

.text-button {
    opacity: 0;
    margin-bottom: 10px;
}

.dark-mode .text-button {
    color: white !important;
}

.text-item {
    margin-bottom: 10px;
}

.selected {
    text-decoration: underline;
}

.active, .selected {
    font-weight: bold!important;
}