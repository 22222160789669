.product-item {
    padding: 0;
    /* display: flex; */
    flex: 1;
    flex-direction: column;
    align-items: center;
    /* width: 23.625vw; */
    max-width: 340px;
    max-height: 450px;
    margin-bottom: 80px;
}

.image-container {
    width: 100%;
    height: 380px;
    background-color: transparent; /* Background color for smaller images */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.product-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.product-description {
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 72px;
    margin-top: 19px;
}

.dark-mode .product-description {
    color: white;
}

.product-name-price, .product-seller-size {
    display: flex;
		
    justify-content: space-between;
}

.product-seller-size {
    margin-top: 8px;
}

.product-name {
    width: 70.658%;
    height: 44px;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
}
.dark-mode .product-name {
	  color: white;
}


.product-price {
    height: 27px;
    color: black;
    font-size: 22px;
    font-weight: 600;
}

.product-sizes {
    align-self: flex-end;
}

.product-sizes, .product-seller {
    color: #767575;
    font-size: 16.457px;
    font-weight: 400;
}

.dark-mode .product-price {
    color: white;
}

.dark-mode .product-sizes {
    color: white;
}

.product-seller {
    position: relative;
    display: inline-block;
}
  
.product-seller .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    font-size: 10px;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    width: 120px;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.5s;
    margin-top: 10px;
}
  
.product-seller:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.product-seller .tooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

/* .dark-mode {
    background-color: black;
    color: white;
}

.product-card {
    color: white;
    font-size: 12px;
    font-weight: 200;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: normal;
    text-align: right;
}

.product-item-link {
    text-decoration: none;
    color: white;
}

.product-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
}

img {
    position: relative;
    width: 450px;
    height: 500px;
    object-fit: cover;
}

.product-image {
    position: relative;

}

.quick-look {
    position: absolute;
    left: 60%;
    bottom: 15px;
    transform: translateX(-50%);
    display: flex;
    gap: 30px;
}

.quick-look-button,
.heart-button {
    background-color: black;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quick-look-button {
    height: 40px;
    padding: 20px 40px;
}


.quick-look-button:hover {
    background-color: white;
    color: black;
}

.heart-button {
    width: 40px;
    height: 40px;
}

.heart-button img {
    width: 100%;
    height: 100%;
}


.product-sizes {
    text-align: right;
    padding-top: 12%
}

.product-sizes ul {
    list-style-type: none;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: normal;
    text-align: right;
    padding-bottom: 10px;
    cursor: pointer;
    outline: none;
}


.product-seller {
    padding-top: 12%;
    position: absolute;
    left: 0;
    text-align: left;
}


.product-name {
    position: absolute;
    left: 0;
    text-align: left;
}


.no-image {
    width: 100%;
    height: 200px;
}
 */

