.seller-modal-content {
    width: 367px;
    height: 402px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.seller-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Darken the background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High index value to overlap the other elements */
}

#sellerDetails {
    text-align: center;
    height: 326px;
    width: 247px;
}

#sellerImage {
    width: 111px;
    height: 111px;
}

#sellerName {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

#follow {
    display: flex;
    border: 1px solid #000;
    width: 129px;
    height: 42px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    margin-left: 58px;
}

#horizontal {
    margin-top: 24px;
    height: 0.5px;
    width: 247px;
    background-color: black;
}

#items {
    font-size: 20px;
    font-weight: 400;
}

#rating {
    margin-top: 5px;
}